import React from 'react';
import { bool, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  NamedRedirect,
} from '../../components';
import { TopbarContainer } from '../../containers';

import audienceImage from './images/audience-image.jpg';
import artistImage from './images/artist-image.jpg';

import css from './RoleSelectionPage.module.css';

export const RoleSelectionPageComponent = props => {
  const { intl, scrollingDisabled, currentUser } = props;
  const title = intl.formatMessage(
    {
      id: 'RoleSelectionPage.schemaTitle',
    },
    { siteTitle: config.siteTitle }
  );

  if (currentUser) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <div className={css.content}>
              <div className={css.titleWrapper}>
                <h1 className={css.title}>
                  <FormattedMessage id="RoleSelectionPage.title" />
                </h1>
                <p className={css.subTitle}>
                  <FormattedMessage id="RoleSelectionPage.subTitle" />
                </p>
              </div>
              <div className={css.signupBoxes}>
                <NamedLink
                  className={css.signupBox}
                  name="SignupPage"
                  params={{ role: config.userRoles['audience'] }}
                >
                  <h2 className={css.signupBoxTitle}>
                    <FormattedMessage id="RoleSelectionPage.audience" />
                  </h2>
                  <img
                    className={css.signupBoxImage}
                    src={audienceImage}
                    alt={intl.formatMessage({
                      id: 'RoleSelectionPage.audience',
                    })}
                  />
                </NamedLink>
                <NamedLink
                  className={css.signupBox}
                  name="SignupPage"
                  params={{ role: config.userRoles['artist'] }}
                >
                  <h2 className={css.signupBoxTitle}>
                    <FormattedMessage id="RoleSelectionPage.artist" />
                  </h2>
                  <img
                    className={css.signupBoxImage}
                    src={artistImage}
                    alt={intl.formatMessage({
                      id: 'RoleSelectionPage.artist',
                    })}
                  />
                </NamedLink>
              </div>
              <p className={css.loginText}>
                <FormattedMessage
                  id="RoleSelectionPage.loginText"
                  values={{
                    loginLink: (
                      <NamedLink
                        className={css.loginLink}
                        name="LoginPage"
                        params={{ role: 'audience' }}
                      >
                        <FormattedMessage id="RoleSelectionPage.loginLink" />
                      </NamedLink>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

RoleSelectionPageComponent.defaultProps = {
  currentUser: null,
};

RoleSelectionPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const RoleSelectionPage = compose(
  connect(mapStateToProps),
  injectIntl
)(RoleSelectionPageComponent);

export default RoleSelectionPage;
